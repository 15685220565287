<script lang="ts">
	import Api, { ApiActiveTransferCount } from "../../../Api";
	import InfoboardLine from "../../../lib/InfoboardLine.svelte";
	import Protect from "../../../lib/Protect.svelte";
	import { t } from "../../../lib/locale";
	import { UserRole } from "../../../types/Enums";
	import InternetConnectionStatus from "../../InternetConnectionStatus.svelte";
	import NavAdminSection from "../../NavAdminSection.svelte";
	import NavItem from "../../NavItem.svelte";
	import NavServerSection from "../../NavServerSection.svelte";
	import NavUser from "../../NavUser.svelte";
	import LayoutBase from "./LayoutBase.svelte";
	import { User } from "../../../store";

	let user: User | undefined = undefined;
	User.subscribe(x => user = x);
</script>

<LayoutBase>
	<svelte:fragment slot="sidebar">
		<NavItem icon="fa fa-home" text={t('menu.home', 'Přehled')} href="/" />
		<NavItem icon="fa fa-plus" text={t('menu.createServer', 'Vytvořit ')} href="/servers/create" />
		<NavItem icon="fa fa-server" text={t('menu.servers', 'Servery')} href="/servers" exact />

		<Protect roles={[
			UserRole.ADMIN,
			UserRole.INSIDER,
			UserRole.CLIENT,
		]}>
			<NavItem icon="fa-solid fa-globe" text={t('menu.domains', 'Domény')} href="/domains" exact />
		</Protect>

		<NavItem icon="fa-solid fa-coins" text={t('menu.payments', 'Kredit')} href="/credit" />
		<NavItem icon="fa-solid fa-user" text={t('menu.settings', 'Váš účet')} href="/profile" />

		<NavServerSection />
		<NavAdminSection />

		<InternetConnectionStatus />
	</svelte:fragment>

	<svelte:fragment slot="header">
		{#if !Api.isProduction()}
			<span class="warning" style="padding: 0.4rem 1rem;">
				<b>{Api.environment.name.toUpperCase()}</b>: {t('component.header.test-env', 'Nacházíte se v testovacím prostředí')}

				{#if $ApiActiveTransferCount}
					| API dotazy: {$ApiActiveTransferCount}
				{/if}
			</span>
		{:else}
			<NavItem icon={null} text={t("component.header.prod-env", 'Helkor Dashboard')} compact />
		{/if}
		<InfoboardLine />
	</svelte:fragment>

	<svelte:fragment slot="header-actions">
		<!-- <NavItem icon="fas fa-book" text="Návody" compact href="https://helkor.eu/help" nativeLink /> -->
		<!-- <NavItem icon="fas fa-bell" text="Oznámení" compact /> -->
		<NavItem
			icon="fa fa-coins"
			text={(Math.round((user?.credits ?? 0)*100)/100).toString()}
			href="/credit" compact
		/>
		{#if user}
			<NavUser user={user} />
		{/if}
	</svelte:fragment>

	<svelte:fragment slot="header-actions-mobile">
		{#if user}
			<NavUser user={user} mobile />
		{/if}
	</svelte:fragment>

	<slot />
</LayoutBase>
